/**
 * @generated SignedSource<<608ec5ff635fc255bc45fb504655eb4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DateTime = "Long" | "String";
export type AddNewsInput = {
  caption: string;
  content: string;
  image?: any | null;
  leakCollections?: ReadonlyArray<string> | null;
  source?: string | null;
};
export type AddNewsMutation$variables = {
  data: ReadonlyArray<AddNewsInput>;
};
export type AddNewsMutation$data = {
  readonly addNews: {
    readonly data: ReadonlyArray<{
      readonly caption: string;
      readonly content: string;
      readonly created: DateTime;
      readonly id: any;
      readonly imageLink: string | null;
      readonly source: string | null;
    }>;
    readonly rejections: ReadonlyArray<{
      readonly key: {
        readonly id: any | null;
        readonly value: string | null;
      };
      readonly rejections: ReadonlyArray<{
        readonly id: string;
        readonly message: string;
      }>;
    }>;
  } | null;
};
export type AddNewsMutation = {
  response: AddNewsMutation$data;
  variables: AddNewsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "NewsAdditionResult",
    "kind": "LinkedField",
    "name": "addNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "News",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "caption",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageLink",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "Timestamp"
              }
            ],
            "kind": "ScalarField",
            "name": "created",
            "storageKey": "created(format:\"Timestamp\")"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rejection",
        "kind": "LinkedField",
        "name": "rejections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionKey",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionMessage",
            "kind": "LinkedField",
            "name": "rejections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNewsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d6e9b8bae4941d629194e3da228916a1",
    "id": null,
    "metadata": {},
    "name": "AddNewsMutation",
    "operationKind": "mutation",
    "text": "mutation AddNewsMutation(\n  $data: [AddNewsInput!]!\n) {\n  addNews(data: $data) {\n    data {\n      id\n      caption\n      content\n      source\n      imageLink\n      created(format: Timestamp)\n    }\n    rejections {\n      key {\n        id\n        value\n      }\n      rejections {\n        id\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a6a89295d5637318f8dbce3b6de5e37";

export default node;
