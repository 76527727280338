import styled, { css } from 'styled-components';

import { Dropdown, IconButton, Node } from 'combinezone/core';

export const Action = styled(IconButton)`
  height: 100%;
  padding: 8px 16px;
  width: 56px;

  & svg {
    color: #fff;
    opacity: 0.4;
  }

  &:active,
  &:hover {
    background: #354864;
    border-radius: 0px;
    opacity: 0.8;
    & svg {
      opacity: 0.8;
    }
  }
  ${({ isPressed }) =>
    isPressed &&
    css`
      background: #354864;
      opacity: 0.8;
      & svg {
        opacity: 0.8;
      }
    `}
`;

export const StyledDropdown = styled(Dropdown)`
  margin-top: 8px;
`;

export const StyledNode = styled(Node)`
  width: 200px;
  box-sizing: border-box;
  min-height: unset;
  padding: 10px 16px;
  &:hover {
    background-color: ${({ theme }) => theme.components.node.colors.hover};
  }
`;

export const DropdownSplitter = styled.div`
  background-color: ${({ theme }) => theme.components.block.colors.border};
  height: 1px;
  margin: 8px 0;
  width: 100%;
`;

export const DocumentationContainer = styled.div`
  width: 168px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
