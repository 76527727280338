import { FC } from 'react';

import { FileExport, Help } from 'combinezone/icons';

import {
  Action,
  DocumentationContainer,
  StyledDropdown,
  StyledNode,
} from './Actions.styles';
import { Text } from 'combinezone/core';

const HelpAction: FC = () => {
  const openPDF = () =>
    window.open(`${process.env.PUBLIC_URL}/User_Guide_BP.pdf`, '_blank');
  return (
    <StyledDropdown
      content={({ onClose }) => (
        <StyledNode
          testId="HelpDocumentation"
          onClick={() => {
            openPDF();
            onClose();
          }}
        >
          <DocumentationContainer>
            <Text>Документация</Text>
            <FileExport />
          </DocumentationContainer>
        </StyledNode>
      )}
      returnFocusOnClose={false}
      position="bottom-left"
    >
      {({ isOpen }) => (
        <Action
          testId="SettingNavButton"
          icon={() => <Help color="#fff" />}
          isPressed={isOpen}
          tooltip={null}
        />
      )}
    </StyledDropdown>
  );
};

export default HelpAction;
