import { FC, useCallback } from 'react';

import { useMutation } from 'react-relay';
import ConnectionHandler from 'relay-connection-handler-plus';

import { URL_REGEXP } from '#Assets/regexps';
import { AddNewsMutation, AddNewsMutation$data } from '#__artifacts/AddNewsMutation.graphql';
import {
  Button,
  FileInput,
  Flex,
  Input,
  Modal,
  Textarea,
  useToast,
} from 'combinezone/core';

import Form from '../../Form/Form';
import { Field, FormField } from '../../Form';
import FormSubmit from '../../Form/Form_Actions';
import { addNewsMutation } from './AddNews.graphql';

const CONTENT_TEMPLATE = `Service:
String:
Count:
Source:
Published on:`;

const FORM_ID = 'AddNews';

const AddNewsModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { toastSuccess, toastError } = useToast();
  const [addNewsMutate, isInProgress] =
    useMutation<AddNewsMutation>(addNewsMutation);

  const onSubmit = useCallback(
    ({
      content,
      caption,
      source,
      image,
    }: {
      content: string;
      caption: string;
      source?: string;
      image?: File;
    }) => {
      addNewsMutate({
        variables: {
          data: [
            {
              source,
              caption,
              content,
              image,
            },
          ],
        },
        onCompleted: (response: AddNewsMutation$data) => {
          const data = response?.addNews?.data;
          const rejections = response?.addNews?.rejections;

          if (rejections && rejections.length > 0) {
            toastError({
              title: 'Что-то пошло не так...',
              message: 'Повторите попытку позже',
            });
          }

          if (data && data.length > 0) {
            toastSuccess({
              title: 'Новость успешно создана',
              durationMs: 1500,
              autoClose: true,
              pauseOnHover: false,
            });
            onClose();
          }
        },
        onError: () => {
          toastError({
            title: `Что-то пошло не так …`,
            message: 'Повторите попытку позже',
          });
        },
        updater: (store, response) => {
          const data = response?.addNews?.data;

          if (data && data.length > 0) {
            const newsFeed = store.get(data[0]?.id);

            if (newsFeed) {
              const connections = ConnectionHandler.getConnections(
                store.getRoot(),
                'MainPageNewsFeedFragment__news',
                (v) => true,
              );
              connections.forEach((connection) => {
                ConnectionHandler.insertEdgeBefore(
                  connection,
                  ConnectionHandler.createEdge(
                    store,
                    connection,
                    newsFeed,
                    `${newsFeed.getType()}Edge`,
                  ),
                );
              });
            }
          }
        },
      });
    },
    [addNewsMutate, onClose, toastError, toastSuccess],
  );

  return (
    <Form
      onSubmit={onSubmit}
      formId={FORM_ID}
      defaultValues={{
        content: CONTENT_TEMPLATE,
      }}
    >
      <Modal
        testId="AddNewsModal"
        title="Создание новости"
        size="md"
        onClose={onClose}
        content={
          <>
            <Field
              name="caption"
              label="Заголовок"
              isRequired="Поле обязательно для заполнения"
              element={<Textarea testId="NewsCaptionField" maxLength={240} />}
            />
            <Field
              name="content"
              label="Описание"
              isRequired="Необходимо заполнить шаблон описания"
              element={<Textarea testId="NewsContentField" minRows={6} />}
              validate={{
                notChanged: (v: string) =>
                  v !== CONTENT_TEMPLATE ||
                  'Необходимо заполнить шаблон описания',
              }}
            />
            <FormField
              name="image"
              label="Изображение"
              render={({ field: { value, onChange } }) => (
                <FileInput
                  testId="NewsImageField"
                  placeholder="Перетащите сюда или выберите изображение"
                  hint="Размер файла не должен превышать 10 МБ"
                  buttonType="icon"
                  accept="image/*"
                  maxSizeMB={10}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Field
              name="source"
              label="Ссылка на источник"
              element={<Input testId="NewsSourceField" isClearable />}
              hint="Обязательно наличие http/https заголовков"
              validate={{
                pattern: (rawValue: string | undefined) => {
                  switch (true) {
                    case !rawValue:
                    case URL_REGEXP.test((rawValue as string).trim()):
                      return;
                    default:
                      return 'Некорректная ссылка';
                  }
                },
              }}
            />
          </>
        }
        footerContent={
          <Flex justify="flex-end" gap="8px">
            <FormSubmit formId={FORM_ID} isLoading={isInProgress}>
              Создать
            </FormSubmit>
            <Button testId="testId" onClick={onClose}>
              Отменить
            </Button>
          </Flex>
        }
      />
    </Form>
  );
};

export default AddNewsModal;
AddNewsModal.displayName = 'AddNewsModal';
