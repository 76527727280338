import { FC, useMemo } from 'react';

import { DateTime } from 'luxon';

import { useFragment } from 'react-relay/hooks';
import { Delete, Edit } from 'combinezone/icons';
import { useConfirm } from '#Components/Confirm';
import { useModals } from '#Components/ModalPortal';
import DeleteSelectedNews from '#Features/DeleteSelectedNews';
import { DATETIME_MED_WITH_ZEROES } from '#Utils/DateTimeFormat';
import {
  Caption,
  Heading,
  IconButton,
  KeyValueList,
  Text,
} from 'combinezone/core';

import EditNewsModal from '#Modals/EditNews';
import { newsFeedItemFragment } from '../NewsFeed/NewsFeed_Item';
import {
  ActionBarNews,
  NewsSidebarHeader,
  NewsSidebarIn,
  NewsSidebarOut,
  NewsSidebarPreview,
  NewsSidebarPreviewOut,
  NewsSidebarSource,
  StyledTextLinkNews,
} from './NewsSidebar.styles';
import { useAccount } from '#Providers/AccountProvider/context';

const valueFormatter = (value: string): string => {
  const clearValue = value.trim();
  if (!clearValue.length) {
    return '-';
  }
  return clearValue;
};

const NewsSidebar: FC<{
  itemRef: any;
  nextSelectedItemId: string | null;
  setIsErrorNews: (isErrorNews: boolean) => void;
}> = ({ itemRef, nextSelectedItemId, setIsErrorNews }) => {
  const { isAdmin } = useAccount();
  const { openModal, closeModal } = useModals();

  const { showConfirm } = useConfirm();

  const newsItem = useFragment(newsFeedItemFragment, itemRef);

  const content = useMemo(() => {
    const contentRows = newsItem.content
      .split(/\n/)
      .map((contentLine: string) => {
        const splitOnIndex = contentLine.indexOf(':');
        const key = contentLine.slice(0, splitOnIndex);
        const value = contentLine.slice(splitOnIndex + 1).trim();
        return {
          key: key.trim(),
          value: <Text isClipped>{valueFormatter(value)}</Text>,
        };
      });
    return <KeyValueList key={newsItem.id} items={contentRows} />;
  }, [newsItem]);

  return (
    <NewsSidebarOut>
      <NewsSidebarHeader>
        <Text isClipped numOfLines={1} showTooltipIfClipped={false}>
          <Heading size="md">{newsItem.caption}</Heading>
        </Text>
        <ActionBarNews>
          {isAdmin ? (
            <>
              <IconButton
                icon={Edit}
                tooltip={null}
                testId="EditNews_IconButton"
                onClick={() =>
                  openModal(
                    <EditNewsModal model={newsItem} onClose={closeModal} />,
                  )
                }
              />
              <IconButton
                icon={Delete}
                tooltip={null}
                testId="DeleteNews_IconButton"
                onClick={() =>
                  showConfirm(
                    <DeleteSelectedNews
                      newsIds={[newsItem?.id]}
                      nextSelectedItemId={nextSelectedItemId}
                      setIsErrorNews={setIsErrorNews}
                    />,
                  )
                }
              />
            </>
          ) : null}
        </ActionBarNews>
      </NewsSidebarHeader>
      <NewsSidebarIn>
        <NewsSidebarPreviewOut>
          <NewsSidebarPreview src={newsItem.imageLink} />
        </NewsSidebarPreviewOut>
        <Caption isParagraph>
          {DateTime.fromSeconds(Number(newsItem.created)).toLocaleString(
            DATETIME_MED_WITH_ZEROES,
          )}
        </Caption>
        <Heading size="md">{newsItem.caption}</Heading>
        {content}
        {newsItem.source && (
          <NewsSidebarSource>
            <Text variant="secondary" isParagraph>
              Source link
            </Text>
            <StyledTextLinkNews
              to={newsItem.source}
              isExternal
              testId={`LinkTo_${newsItem.source}`}
            >
              {newsItem.source}
            </StyledTextLinkNews>
            {/* <NewsDivider />
            <NewsFeedback>
              <ButtonTransparent
                LeftIcon={HandLike}
                testId="LikeNews_IconButton"
                onClick={() => { }}
              >
                <Text>12069</Text>
              </ButtonTransparent>
              <ButtonTransparent
                LeftIcon={HandDislike}
                testId="DislikeNews_IconButton"
                onClick={() => { }}
              >
                <Text>290</Text>
              </ButtonTransparent>
            </NewsFeedback> */}
          </NewsSidebarSource>
        )}
      </NewsSidebarIn>
    </NewsSidebarOut>
  );
};

export default NewsSidebar;
NewsSidebar.displayName = 'NewsSidebar';
