import React, { FC } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { SearchInput } from 'combinezone/core';
import { StringParam, useQueryParam } from 'use-query-params';
import styled from 'styled-components';

const SearchInputOut = styled.div`
  width: 480px;
`;

const FilterValueRegex: FC = () => {
  const [value, setValue] = useQueryParam(FilterName.ValueRegex, StringParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  return (
    <FilterBase label={null}>
      <SearchInputOut>
        <SearchInput
          value={value ?? ''}
          onChange={(v) => {
            const clearValue = v.trim();
            setValue(clearValue.length ? clearValue : undefined);
          }}
          changeDelayMs={1000}
          testId="searchInput"
        />
      </SearchInputOut>
    </FilterBase>
  );
};

export default FilterValueRegex;
FilterValueRegex.displayName = 'FilterValueRegex';
