import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { BlockContainer } from 'combinezone/core';

export const NewsFeedListOut = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  & ${BlockContainer} {
    height: 100%;
  }
`;

export const NewsFeedListIn = styled.div<{ ref: any }>`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

export const NewsItemOut = styled(NavLink) <Partial<NavLinkProps>>`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.components.card.colors.border.normal};
  border-radius: 4px;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 148px;
  margin: 16px 16px 0;
  text-decoration: none;
  box-sizing: border-box;
  &.active {
    background: ${({ theme }) =>
    theme.components.card.colors.background.selected};
    border-color: ${({ theme }) =>
    theme.components.card.colors.border.selected};
  }

  &:hover {
    border-color: #999999;
    box-shadow: 0px 0px 4px 0px #4d4d4d33;
  }
`;

export const NewsItemPreviewOut = styled.div`
  display: flex;
  width: 248px;
  height: 116px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 248px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${({ theme }) =>
    theme.isDark ? '/noimage-dark.svg' : '/noimage.svg'})
      no-repeat center center;
    top: 0;
    left: 0;
  }
`;

export const NewsItemPreviewOutSrc = styled.div`
  display: flex;
  width: 248px;
  height: 116px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 248px;

`;

export const NewsItemPreview = styled.img`
  display: block;
  position: relative;
  width: auto;
  height: auto;
  max-width: 248px;
  max-height: 116px;
  object-fit: contain;
  z-index: 1;
`;

export const NewsItemSource = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  gap: 8px;
  width: 100%;
  & span {
    white-space: nowrap;
  }
`;

export const NewsItemIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  overflow: hidden;
  flex-grow: 0;
`;

export const NewsFeedListInWrapper = styled.div<{
  totalSize: number;
}>`
  height: ${({ totalSize }) => totalSize}px;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
`;

export const NewsItemWrapper = styled.div<{
  virtualRowSize: number;
  virtualRowStart: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ virtualRowSize }) => virtualRowSize}px;
  transform: translateY(${({ virtualRowStart }) => virtualRowStart}px);
`;

export const InfiniteScrollTriggerNewsWrapper = styled.div<{
  virtualRowSize: number;
  virtualRowStart: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translateY(
    ${({ virtualRowStart, virtualRowSize }) =>
    virtualRowStart + virtualRowSize}px
  );
`;
