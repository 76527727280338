import { graphql } from 'babel-plugin-relay/macro';

export const addNewsMutation = graphql`
  mutation AddNewsMutation($data: [AddNewsInput!]!) {
    addNews(data: $data) {
      data {
        id
        caption
        content
        source
        imageLink
        created(format: Timestamp)
      }
      rejections {
        key {
          id
          value
        }
        rejections {
          id
          message
        }
      }
    }
  }
`;