import React, { FC, memo } from 'react';

import { DateTime } from 'luxon';

import { useFragment } from 'react-relay/hooks';
import { graphql } from 'babel-plugin-relay/macro';
import { Caption, Heading, Text } from 'combinezone/core';
import type, {
  NewsFeedItemFragment$key,
} from '#__artifacts/NewsFeedItemFragment.graphql';

import { DATETIME_MED_WITH_ZEROES } from '../../../../Utils/DateTimeFormat';
import {
  NewsItemIn,
  NewsItemOut,
  NewsItemPreview,
  NewsItemPreviewOut,
  NewsItemPreviewOutSrc,
  NewsItemSource,
} from './NewsFeed.styles';

export const newsFeedItemFragment = graphql`
  fragment NewsFeedItemFragment on News {
    id
    content
    caption
    source
    imageLink
    created(format: Timestamp)
  }
`;

const Preview: FC<{ src: string | null }> = memo(({ src }) =>
  src ? (
    <NewsItemPreviewOutSrc>
      {src && <NewsItemPreview src={src} />}
    </NewsItemPreviewOutSrc>
  ) : (
    <NewsItemPreviewOut />
  ),
);

const NewsFeedItem: FC<{ newsData: NewsFeedItemFragment$key }> = ({
  newsData,
}) => {
  const data = useFragment<NewsFeedItemFragment$key>(
    newsFeedItemFragment,
    newsData,
  );

  return (
    <NewsItemOut to={`/news/${data.id}`}>
      <Preview src={data.imageLink} key={data.id} />
      <NewsItemIn>
        <Caption>
          {DateTime.fromSeconds(Number(data.created)).toLocaleString(
            DATETIME_MED_WITH_ZEROES,
          )}
        </Caption>
        <Text isClipped numOfLines={2} showTooltipIfClipped={false}>
          <Heading>{data.caption}</Heading>
        </Text>
        {data.source && (
          <NewsItemSource>
            <Text variant="secondary">Ссылка на источник:</Text>
            <Text isClipped showTooltipIfClipped={false}>
              {data.source}
            </Text>
          </NewsItemSource>
        )}
      </NewsItemIn>
    </NewsItemOut>
  );
};

export default memo(NewsFeedItem);
NewsFeedItem.displayName = 'NewsFeedItem';
